<template>
  <div class="policyInfo">
    <div class="iframeBox">
      <iframe
        :src="detailData.fileUrl"
        :frameborder="0"
        width="100%"
        height="100%"
      ></iframe>
    </div>
  </div>
</template>
<script>
export default {
  name: "policyInfo",
  props: {
    detailData: {
      type: Object,
      data() {
        return {
          fileUrl: "",
        };
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.policyInfo {
  padding: 20px;
  height: 70vh;
  display: flex;
  flex-direction: column;
  .iframeBox {
    flex: 1;
    width: 100%;
  }
}
</style>
