<template>
  <el-form
    :model="formData"
    :rules="rules"
    ref="formRef"
    label-width="120px"
    label-suffix="："
  >
    <el-form-item label="激励名称" prop="actName">
      <el-input
        placeholder="请输入激励名称"
        v-model="formData.actName"
        :disabled="disabledActName"
      ></el-input>
    </el-form-item>
    <el-form-item label="活动开始时间" prop="actStartTime">
      <el-date-picker
        type="datetime"
        placeholder="请选择活动开始时间"
        v-model="formData.actStartTime"
        format="yyyy-MM-dd HH:mm:ss"
        value-format="yyyy-MM-dd HH:mm:ss"
      ></el-date-picker>
    </el-form-item>
    <el-form-item label="活动结束时间" prop="actEndTime">
      <el-date-picker
        type="datetime"
        placeholder="请选择活动结束时间"
        v-model="formData.actEndTime"
        format="yyyy-MM-dd HH:mm:ss"
        value-format="yyyy-MM-dd HH:mm:ss"
      ></el-date-picker>
    </el-form-item>
    <el-form-item label="活动文件URL" prop="fileUrl">
      <el-input
        v-model="formData.fileUrl"
        placeholder="请输入活动文件URL，以https开头"
      ></el-input>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="handleSubmit()" :disabled="disabled"
        >立即提交</el-button
      >
      <el-button @click="handleCancel()">返回</el-button>
    </el-form-item>
  </el-form>
</template>
<script>
import UploadFile from "@/components/UploadFile.vue";
export default {
  components: { UploadFile },
  props: {
    detailData: {
      type: Object,
      default() {
        return {};
      },
    },
    operationType: { default: "" },
  },
  data() {
    const checkActEndTime = (rule, value, callback) => {
      const startTime = new Date(this.formData.actStartTime).getTime();
      const endTime = new Date(value).getTime();
      if (value && endTime < startTime) {
        return callback(new Error("活动结束时间不能小于活动开始时间"));
      } else {
        callback();
      }
    };
    const checkUrl = (rule, value, callback) => {
      // 校验url,以https开头
      // eslint-disable-next-line no-useless-escape
      const patt = /(http|https):\/\/([\w.]+\/?)\S*/;
      if (value && !patt.test(value)) {
        return callback(new Error("请输入正确的网址"));
      } else {
        callback();
      }
    };
    return {
      formData: {},
      rules: {
        actName: [{ required: true, message: "请输入激励名称" }],
        actStartTime: [{ required: true, message: "请选择活动开始时间" }],
        actEndTime: [
          { required: true, message: "请选择活动结束时间" },
          { validator: checkActEndTime },
        ],
        fileUrl: [
          { required: true, message: "请输入活动文件URL" },
          { validator: checkUrl },
        ],
      },
    };
  },
  computed: {
    disabled() {
      return this.operationType === "view";
    },
    disabledActName() {
      return this.operationType === "edit";
    },
  },
  methods: {
    handleSubmit() {
      this.$refs.formRef.validate((valid) => {
        let uploadValid = true;
        if (this.$refs.uploadRef) {
          // 存在文件上传时，校验文件是否已上传完成
          uploadValid = this.$refs.uploadRef.getValidate();
        }
        if (valid && uploadValid) {
          this.$emit("submit", this.formData);
        }
      });
    },
    handleCancel() {
      this.$emit("cancel");
    },
    // 其他
    initData(data) {
      this.formData = {
        actName: "",
        actStartTime: "",
        actEndTime: "",
        fileUrl: "",
        ...data,
      };
    },
  },
  watch: {
    detailData: {
      immediate: true,
      handler(data) {
        this.initData(data);
        if (this.operationType === "create" || this.operationType === "edit") {
          this.$refs.formRef && this.$refs.formRef.resetFields();
        }
      },
    },
  },
};
</script>
<style scoped lang="scss">
.el-input {
  width: 500px;
}
</style>
