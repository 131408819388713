import request from "@/config/serve.js";

// 查询
export function getActivityPolicyList(data) {
  return request(
    "post",
    "/apiGo/api/v1/app/manage/getActivityPolicyList",
    data
  );
}
// 查询详情
export function getActivityPolicyInfo(params) {
  return request(
    "get",
    "/apiGo/api/v1/app/manage/getActivityPolicyInfo",
    params
  );
}
//新增
export function saveActivityPolicy(data) {
  return request("post", "/apiGo/api/v1/app/manage/saveActivityPolicy", data);
}
//删除
export function deleteActivityPolicy(data) {
  return request("post", "/apiGo/api/v1/app/manage/delActivityPolicy", data);
}
